<template>
  <v-container
    class="pt-sm-9"
    style="height: 100%;"
  >
    <v-row
      class="mb-3"
      no-gutters
      align="center"
    >
      <v-col
        class="d-none d-sm-flex"
        cols="auto"
      >
        <ProjectAdder
          v-model="dialogProject"
          @reloadProjects="fetchProjects"
        >
          <template #activator="{ on, attr }">
            <v-btn
              class="btn-add-project"
              color="primary"
              v-bind="attr"
              :disabled="isLoadingProjects"
              v-on="on"
              @click="addProject"
            >
              {{ $t('page.projects.add_project_title') }}
            </v-btn>
          </template>
        </ProjectAdder>
      </v-col>

      <v-spacer />

      <v-col cols="auto">
        <v-btn-toggle
          v-model="showPermission"
          class="permission-btn-group"
          group
          dense
          mandatory
          @change="fetchProjects(false)"
        >
          <v-btn
            class="ma-0"
            value="owner"
            text
          >
            {{ $t('own') }}
          </v-btn>
          <v-btn
            class="ma-0"
            value="member"
            text
          >
            {{ $t('involved') }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="isLoadingProjects"
      absolute
      indeterminate
      color="primary"
    />

    <IssueCard
      v-else-if="hasNoData"
      class="mt-13"
      :message="issueCardMessage"
      :error="error"
    />

    <v-row no-gutters>
      <ProjectList
        v-show="!hasNoData"
        :projects="showProjects"
        @reloadProjects="fetchProjects"
        @editProject="editProject"
      />
    </v-row>
  </v-container>
</template>

<script>
import IssueCard from '@/components/IssueCard'
import ProjectAdder from '@/components/Project/ProjectAdder/ProjectAdder'
import ProjectList from '@/components/Project/ProjectList'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Projects',
  components: {
    IssueCard,
    ProjectAdder,
    ProjectList
  },
  data: () => ({
    error: false,
    dialogProject: false,
    showPermission: 'owner' // owner | member
  }),
  computed: {
    ...mapState({
      formData: state => state.projects.form.formData,
      projects: state => state.projects.projects,
      isLoadingProjects: state => state.projects.isLoadingProjects
    }),
    ...mapGetters({
      ownerProjects: 'projects/ownerProjects',
      memberProjects: 'projects/memberProjects'
    }),

    showProjects() {
      if (this.showPermission === 'member') return this.memberProjects

      return this.ownerProjects
    },
    hasNoData() {
      return (
        this.error ||
        !Array.isArray(this.showProjects) ||
        this.showProjects.length === 0
      )
    },
    issueCardMessage() {
      // fetch api error
      if (this.error) {
        return this.$t('api_errors.data_error_refresh_page')
      }

      // no data text
      return this.showPermission === 'owner'
        ? this.$t('page.projects.no_project_own')
        : this.$t('page.projects.no_project_involved')
    }
  },
  created() {
    this.fetchResourceIds()
    this.fetchProjects().then(() => {
      if (!this.ownerProjects.length && this.memberProjects.length) {
        this.showPermission = 'member'
      }
    })
  },
  methods: {
    fetchProjects(forceUpdate = true) {
      if (!forceUpdate && this.isLoadingProjects) return

      this.error = false
      return this.$store.dispatch('projects/fetchProjects').catch(() => {
        this.error = true
      })
    },
    fetchResourceIds() {
      return this.$store.dispatch('fetchResourceIds')
    },
    addProject() {
      this.$store.dispatch('projects/form/init')
    },
    editProject(project) {
      this.$store.dispatch('projects/form/edit', { project }).then(() => {
        this.dialogProject = true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-btn-toggle.permission-btn-group {
  .v-btn.v-item--active {
    color: $color-primary;
  }
}

.v-btn:not(.v-btn--round).v-size--default.btn-add-project {
  padding: 20px 2.25rem;
  font-size: 1.25rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}

.v-data-table {
  height: calc(69vh - 131px);

  ::v-deep .v-data-table__wrapper {
    overflow-y: auto;
    height: 100%;
  }
}
</style>
