<template>
  <v-sheet
    color="grey lighten-3"
    height="100%"
  >
    <v-row
      class="px-8 py-3 bg-ease flex-grow-0"
      no-gutters
    >
      <v-col class="step-title white--text">
        {{ $t('page.projects.add_project_team_title') }}
      </v-col>
    </v-row>

    <v-row
      class="my-3"
      no-gutters
      style="height: calc(100% - 57px - 24px);"
    >
      <!-- teams col -->
      <v-col
        class="step-col px-7 white"
        style="flex-basis: 672px;"
      >
        <v-row
          class="py-4"
          no-gutters
        >
          <v-spacer />

          <v-col cols="auto">
            <v-btn
              color="primary"
              small
              icon
              :disabled="isLoadingTeams"
              @click="fetchTeams"
            >
              <v-icon small>
                mdi-refresh
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-progress-linear
          v-if="isLoadingTeams"
          class="mb-3"
          indeterminate
          color="primary"
        />
        <IssueCard
          v-else-if="hasNoData"
          class="mt-10"
          :message="$t('page.projects.create_team_first')"
          img-width="55%"
        />
        <TeamExpansionPanels
          v-show="!hasNoData"
          :teams="teams"
          member-read-only
          @joinTeam="joinTeam"
        />
      </v-col>

      <!-- joined col -->
      <v-col
        class="step-col white ml-3 pt-4 px-7 d-flex flex-column"
        cols="auto"
        style="flex-basis: 416px;"
      >
        <ProjectJoinedTeams />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import IssueCard from '@/components/IssueCard'
import TeamExpansionPanels from '@/components/TeamAdder/TeamExpansionPanels'
import ProjectJoinedTeams from './ProjectJoinedTeams'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ProjectAdderStep2',

  components: {
    IssueCard,
    TeamExpansionPanels,
    ProjectJoinedTeams
  },

  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      formData: state => state.projects.form.formData,
      isLoadingTeams: state => state.teams.isLoadingTeams
    }),
    ...mapGetters({
      ownerTeams: 'teams/ownerTeams',
      getFormTeamByTeam: 'projects/form/getFormTeamByTeam'
    }),

    teams() {
      return this.ownerTeams.map(t => {
        return {
          ...t,
          isJoined: this.formTeams.some(fTeam => fTeam.name === t.name)
        }
      })
    },
    formTeams() {
      return this.formData.teams
    },
    hasNoData() {
      return !this.teams.length
    }
  },

  watch: {
    isShow: {
      handler(newVal) {
        if (newVal && !this.teams.length && !this.isLoadingTeams) {
          this.fetchTeams()
        }
      },
      immediate: true
    }
  },

  methods: {
    fetchTeams() {
      this.$store.dispatch('teams/fetchTeams')
    },
    joinTeam(team) {
      const newTeam = this.getFormTeamByTeam(team)
      this.$store.dispatch('projects/form/setFormData', {
        teams: [newTeam, ...this.formTeams]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.step-col {
  overflow: auto;
  max-height: 100%;
  border: solid 0.8px #ddd;
}
</style>
