<template>
  <v-card
    class="filemgr-card"
    elevation="0"
    color="transparent"
  >
    <v-row
      class="panel-row flex-nowrap py-3"
      no-gutters
    >
      <v-col
        class="panel-col"
        style="flex-basis: 500px;"
      >
        <FileTree
          readonly
          style="border: none;"
        />
      </v-col>
      <v-col
        class="panel-col ml-3"
        style="flex-basis: 780px;"
      >
        <FileViewer
          readonly
          style="border: none;"
        />
      </v-col>
      <v-col
        class="
          panel-col
          white
          ml-3
          pt-4 px-7
        "
        style="flex-basis: 700px;"
      >
        <ProjectJoinedFolderPermission />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import FileTree from './Panels/FileTree'
import FileViewer from './Panels/FileViewer'
import ProjectJoinedFolderPermission from '@/components/Project/ProjectAdder/ProjectJoinedFolderPermission'

export default {
  name: 'FileManagerProject',

  components: {
    FileTree,
    FileViewer,
    ProjectJoinedFolderPermission
  }
}
</script>

<style lang="scss" scoped>
.filemgr-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.panel-row {
  flex-grow: 1;
  height: 100%;
}

.panel-col {
  overflow-x: auto;
  height: 100%;
  border: solid 0.8px white;
  border-radius: 0;
}
</style>
