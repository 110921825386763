<template>
  <v-dialog
    v-model="dialog"
    max-width="1200"
    persistent
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      />
    </template>

    <v-card
      height="90vh"
      :disabled="isSending"
    >
      <v-stepper
        v-model="step"
        class="project-adder-stepper"
      >
        <v-stepper-items style="position: relative;">
          <v-stepper-content
            class="stepper-content bg-ease"
            step="1"
          >
            <ProjectAdderStep1 ref="step1" />
          </v-stepper-content>

          <v-stepper-content
            class="stepper-content"
            step="2"
          >
            <ProjectAdderStep2
              ref="step2"
              :is-show="step === 2"
            />
          </v-stepper-content>

          <v-stepper-content
            class="stepper-content"
            step="3"
          >
            <ProjectAdderGrafanaPermission
              ref="step3"
            />
          </v-stepper-content>

          <v-stepper-content
            class="stepper-content"
            step="4"
          >
            <ProjectAdderStep4
              ref="step4"
            />
          </v-stepper-content>

          <v-stepper-content
            class="stepper-content"
            step="5"
          >
            <ProjectAdderStep5
              ref="step5"
              :is-show="step === 5"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-card-actions>
        <v-row
          class="px-8 py-4"
          no-gutters
          justify="space-between"
        >
          <v-col cols="auto">
            <v-btn
              class="btn-cancel"
              :disabled="isSending"
              @click="cancel"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="step > 1"
              class="mr-4"
              color="primary"
              :disabled="isSending"
              @click="prevStep"
            >
              {{ $t('prev_step') }}
            </v-btn>
            <v-btn
              v-if="step < totalStep"
              color="primary"
              :disabled="isSending"
              @click="nextStep"
            >
              {{ $t('next_step') }}
            </v-btn>
            <v-btn
              v-else
              color="primary"
              :loading="isSending"
              @click="submit"
            >
              <template v-if="isEditModel">
                <v-icon class="mr-2">
                  mdi-content-save
                </v-icon>
                <span>{{ $t('save') }}</span>
              </template>
              <span v-else>{{ $t('create') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProjectAdderStep1 from './ProjectAdderStep1'
import ProjectAdderStep2 from './ProjectAdderStep2'
import ProjectAdderGrafanaPermission from './ProjectAdderGrafanaPermission'
import ProjectAdderStep4 from './ProjectAdderStep4'
import ProjectAdderStep5 from './ProjectAdderStep5'

import { MODEL } from '@/store/modules/files/files'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ProjectAdder',

  components: {
    ProjectAdderStep1,
    ProjectAdderStep2,
    ProjectAdderGrafanaPermission,
    ProjectAdderStep4,
    ProjectAdderStep5
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    step: 1,
    totalStep: 5,
    isSending: false
  }),

  computed: {
    ...mapState({
      formData: state => state.projects.form.formData
    }),
    ...mapGetters({
      isEditModel: 'projects/form/editModel',
      isLoadingFolders: 'files/isLoadingFolders'
    }),

    stepRefs() {
      return Array.from({ length: this.totalStep }, (_, i) => `step${i + 1}`)
    },

    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  watch: {
    dialog(newVal) {
      if (newVal) {
        this.$store.dispatch('files/setModel', MODEL.PROJECT)
        if (!this.isLoadingFolders) {
          this.$store.dispatch('files/fetchFileNodes')
        }
      }
    }
  },

  beforeDestroy() {
    this.init()
  },

  methods: {
    init() {
      this.step = 1
      this.$store.dispatch('projects/form/init')
      this.$store.dispatch('files/init')

      this.resetValidation()
    },
    resetValidation() {
      this.stepRefs.forEach(ref => {
        return this.$refs?.[ref]?.resetValidation?.()
      })
    },
    async formValidate(ref) {
      const funValidate = this.$refs?.[ref]?.validate

      if (typeof funValidate !== 'function') {
        return true
      }

      return funValidate()
    },
    cancel() {
      this.dialog = false

      this.init()
    },
    prevStep() {
      this.step -= 1
    },
    async nextStep() {
      const ref = `step${this.step}`
      const isValid = await this.formValidate(ref)

      if (!isValid) return

      this.step += 1
    },
    async submit() {
      const isValid = await Promise.all(
        this.stepRefs.map(ref => this.formValidate(ref))
      ).then(results => results.every(Boolean))

      if (!isValid) return

      const confirm = window.confirm(
        this.isEditModel
          ? this.$t('page.projects.confirm_edit_project')
          : this.$t('page.projects.confirm_create_project')
      )

      if (!confirm) return

      this.isSending = true
      this.$store
        .dispatch('projects/form/submit')
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.isEditModel
              ? this.$t('success_edited')
              : this.$t('success_created')
          })

          this.$emit('reloadProjects')
          this.cancel()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isSending = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.project-adder-stepper {
  height: calc(100% - 84px);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;

  ::v-deep .step-title {
    font-size: 1.375rem;
  }

  .v-stepper__items {
    height: 100%;

    .stepper-content {
      padding: 0;
      height: 100%;

      ::v-deep .v-stepper__wrapper {
        height: 100%;
      }
    }
  }
}

.v-card__actions {
  & .v-btn.v-btn {
    padding: 4px 1.875rem;
    font-size: 1.25rem;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    letter-spacing: normal;

    &.btn-cancel {
      color: #343843;
      border: solid 1px #ddd;
    }
  }
}
</style>
