<template>
  <v-expansion-panels multiple>
    <v-expansion-panel
      v-for="(team, iTeam) in teams"
      :key="iTeam"
      class="team-expansion-panel"
      :class="{ 'mt-1': iTeam > 0 }"
    >
      <v-expansion-panel-header class="py-3 px-4">
        <template #actions>
          <v-icon class="expansion-panel-header-icon font-weight-bold">
            $expand
          </v-icon>
        </template>
        <v-row
          class="expansion-panel-header-text d-inline-flex flex-nowrap"
          align="center"
          no-gutters
          style="max-width: calc(100% - 24px);"
        >
          <v-col class="text-truncate">
            <v-icon
              class="px-2"
              color="primary"
              small
            >
              mdi-account-multiple
            </v-icon>
            {{ team.name }}
          </v-col>

          <v-col
            v-if="Array.isArray(team.members)"
            class="primary--text"
            cols="auto"
            style="width: 130px;"
          >
            {{
              $tc('team_member_count', team.memberCount)
            }}
          </v-col>

          <v-col
            v-if="!readonly"
            cols="auto"
          >
            <v-btn
              v-if="!team.isJoined"
              color="secondary"
              x-small
              rounded
              depressed
              @click.native.stop="joinTeam(team)"
            >
              <v-row
                align="center"
                no-gutters
              >
                <v-icon x-small>
                  mdi-plus
                </v-icon>
                {{ $t('join') }}
              </v-row>
            </v-btn>
            <v-row
              v-else
              class="secondary--text mr-2"
              align="center"
              no-gutters
              style=" min-height: 20px;font-size: 0.625rem;"
            >
              <v-icon x-small>
                mdi-check
              </v-icon>
              {{ $t('joined') }}
            </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="team-expansion-panel-content">
        <v-divider />
        <MemberList
          :members="team.members"
          :readonly="memberReadOnly || readonly"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import MemberList from './MemberList'
import { mapState } from 'vuex'

export default {
  name: 'TeamExpansionPanels',

  components: {
    MemberList
  },

  props: {
    teams: {
      type: Array,
      default: () => []
    },
    memberReadOnly: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      formData: state => state.teams.form.formData
    }),

    formMembers: {
      get() {
        return this.formData.members
      },
      set(newVal) {
        this.$store.dispatch('teams/form/setFormData', {
          members: newVal
        })
      }
    }
  },

  methods: {
    joinTeam(team) {
      this.$emit('joinTeam', team)
      // const members = [...team.members, ...this.formMembers]

      // this.formMembers = Array.from(new Set(members.map(m => m.id)))
      //   .map(mid => members.find(m => m.id === mid))
      //   .filter(Boolean)
    }
    // isTeamJoined(team) {
    //   return team.members.every(member =>
    //     this.formMembers.some(fMember => fMember.id === member.id)
    //   )
    // }
  }
}
</script>

<style lang="scss" scoped>
.expansion-panel-header-icon {
  order: 0;
}

.expansion-panel-header-text {
  order: 1;
}

.v-expansion-panels {
  .v-expansion-panel.team-expansion-panel {
    border: 1px solid #ddd;

    &::before {
      box-shadow: none;
    }

    &:not(:first-child)::after {
      border-color: transparent;
    }

    .team-expansion-panel-content {
      ::v-deep .v-expansion-panel-content__wrap {
        padding-right: 16px;
        padding-bottom: 0;
        padding-left: 16px;
      }
    }
  }
}

.member-list {
  &.v-list--dense {
    .v-list-item {
      min-height: 28px;
    }
  }
}
</style>
